/********************/
/*  Responsive nav  */
/********************/


.header #header-menu ul{
	list-style : none;
    margin : 0rem;
}

.header #header-menu li{
    display : inline-block;
}

.header #header-menu ul li a{
    font-size : 1.3rem;
	color : $primary-color;
    padding-right : 1.5rem;
    padding-left : 1.5rem;
    padding-bottom : 0.5rem;
}

.header #true-header ul>li>a:hover{
	color : $secondary-color;
	box-shadow : 0px 2px 0px $primary-color;
}

@media screen and (max-width: 1023px) {
	.header #header-menu #menu{
		font-size : 1.5em;
        color : $primary-color;
        text-align : center;
        background-color : transparent;
    }
    .header #header-menu #menu.custom-background-color{
        background-color : $light-gray;
    }
    .header #header-menu #menu p{
		font-size : 1.1rem;
        display : inline-block;
        margin-bottom : 0rem;
        padding-right : 10%;
        visibility : hidden;
	}
	.header #header-menu #nav.js{
        text-align : center;
        z-index : 100;
		display: none;
        background-color : $light-gray;
        position : absolute;
        left : 60%;
        width : 40%;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	}
    .header #header-menu #nav.js li a{
        font-size : 1rem;
        padding : 0.5rem;
        color : $secondary-color;
        padding-bottom : 0.75rem;
        display : inline-block;
	}
    .header #header-menu #nav.js li a:hover{
        color : $secondary-color;
        text-decoration : none;
	}
    .header #header-menu #nav.js li:lastchild{
        padding-bottom : 0.5rem;
	}
	.header #header-menu ul{
		width:100%;
		list-style:none;
	}
	.header #header-menu li{
		width:100%;
		border-right:none;
	}
}

@media screen and (min-width: 1023px){
	.header #header-menu #menu {
		display: none;
	}
}