/******************/
/*  Presentation  */
/******************/

#presentation header{
    width : 100%;
}

#presentation header>h1{
    color : $primary-color;
    text-align : center;
    margin : 1rem;
}

/***********/
/*  Aside  */
/***********/

#presentation #presentation-content aside button{
    padding : 0.5rem;
    width : 9rem;
    margin : 0.3rem;
    margin-left : 0rem;
    font-size : 1.2rem;
    border-radius : 5px;
    border-top-right-radius : 25px;
    border-bottom-right-radius : 25px;
    background-color : $primary-color;
    border : 1.5px solid $primary-color;
}

#presentation #presentation-content aside button:hover{
    background-color : white; 
    border : 1.5px solid $primary-color;
}

#presentation #presentation-content aside .shrink button:first-child{
    margin-top : 50px;
}
    
    /************************/
    /*  Small-screen class  */
    /************************/
    
#presentation #presentation-content aside button.small-screen{
    color : transparent;
    width : 2rem;
}

#presentation #presentation-content aside button.small-screen:hover{
    color : white;
    width : 9rem;
    background-color : $primary-color;
}
    

/*************/
/*  Article  */
/*************/

#presentation #presentation-content{
    margin-bottom : 2rem;
}

#presentation #presentation-content .hidden{
    display : none;
}

#presentation #presentation-content article>h2{
    font-size : 2rem;
    font-weight : bold;
}

#presentation #presentation-content article>h3{
    font-size : 1.5rem;
    font-style : italic;
    margin-left : 1rem;
}

#presentation #presentation-content ul{
    list-style-type : circle;
    margin-left : 2rem;
}

/***********/
/*  Image  */
/***********/

#presentation #presentation-content article img{
    width : 10rem;
    display : none;
}

