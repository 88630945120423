        /*---------------*/
		/* Media Queries */
		/*---------------*/

@media screen and (max-width: 639px) {
    .footer #footer-row{
        display : none;
    }
    .footer .footer-bar #footer-row-copyright p{
        font-size : 0.6rem;
    }
}

@media screen and (max-width: 1023px) and (min-width: 640px) {
    .footer i{
        display : none;
    }
}

        /*--------*/
		/* Footer */
		/*--------*/
	
.footer i{
    margin-right : 1rem;
}

.footer>.row{
	border-top : 2px solid $secondary-color;
}

.footer h2{
    line-height : 1.2rem;
    margin-top : 1rem;
    margin-bottom : 1rem;
}

.footer h2>a{
	font-size : 1.5rem;
    color : $primary-color;
}
.footer h2>a:hover{
    color : $secondary-color;
	box-shadow : 0px 2px 0px $primary-color;
    cursor : pointer;
}

.footer ul{
	list-style : none;
}

.footer a{
	color : black;
}

	/*--------------*/
	/* Presentation */
	/*--------------*/
	
.footer ul#footer-row-presentation-ul a:hover{
	color : $primary-color;
}

	/*---------*/
	/* Contact */
	/*---------*/

.footer #footer-row-contact-adresse i{
    vertical-align : top;
}

.footer #footer-row-contact-adresse i:before{
    vertical-align : top;
    padding-top : 0.3rem;
}

.footer #footer-row-contact-adresse-ul{
    display : inline-block;
    margin-left : 0rem;
}

.footer #footer-row-contact-mail a{
	color : $primary-color;
	text-decoration : underline;
} 

.footer ul#footer-row-contact-ul a:hover{
	color : $secondary-color;
}

	/*-------*/
	/* Legal */
	/*-------*/
	
.footer ul#footer-row-legal-ul a:hover{
	color : $primary-color;
}

    /****************/
    /*  Footer-bar  */
    /****************/

.footer-bar{
    background-color : $primary-color;
	border-top : 2px solid $secondary-color;
}

        /*-----------*/
		/* Copyright */
		/*---------- */

.footer #footer-row-copyright{
    max-width : none;
}

.footer #footer-row-copyright{
	font-size : 0.8rem;
}

.footer #footer-row-copyright .small-3.columns{
	padding-right : 1rem;
    padding-left : 1rem;
}

.footer #footer-row-copyright .small-3.small-offset-6.columns{
	text-align : right;
}

.footer #footer-row-copyright p{
    margin : 0rem;
    padding : 0.5rem;
    padding-right : 0.5rem;
    padding-left : 0.5rem;
}

.footer #footer-row-copyright #footer-bar-calendar{
    text-align : right;
}