@media screen and (max-width: 639px) {
    .accueil #accueil-content .columns a{
        padding-left : 0rem;
    }
}

    /*----------*/
	/* Ensemble */
	/*-------s-- */

.accueil>.row{
    margin-bottom : 2rem;
}

		/*-------*/
		/* Title */
		/*------ */

.accueil header.row{
    margin-left : 0rem;
    margin-right : 0rem;
    width : 100%;
}

.accueil header #title{
    width : 100%;
    text-align : center;
    color : $primary-color;
    cursor : default;
    margin-top : 1rem;
    margin-bottom : 1rem;
}

.accueil header>p{
    color : black;
    font-style : italic;
    font-size : 1.3rem;
    text-align : center;
    width : 100%;
}

        /*-----------*/
		/* Slideshow */
		/*-----------*/


.slideshow {
   width: 100%;
   height: 130px;
   overflow: hidden;
   border: 3px solid #F2F2F2;
}

.slideshow ul {
    /* 4 images donc 4 x 100% */
   width: 400%;
   height: 200px;
   padding:0; margin:0;
   list-style: none;
}

.slideshow li {
   float: left;
}
		
		/*---------*/
		/* Content */
		/*-------- */

.accueil #accueil-content{
	margin-top : 2rem;
    margin-bottom : 2rem;
}

.accueil #accueil-content>.row{
	margin : 0rem;
    padding : 0rem;
}

.accueil #accueil-content .columns{
    padding : 2rem;
}

.accueil #accueil-content h2{
	font-size : 1.3rem;
    min-height : 3.6rem;
}

.accueil #accueil-content h2>a{
    color : black;
}

.accueil #accueil-content i{
	padding-right : 0.5rem;
}
.accueil #accueil-content p{
	font-size : 1rem;
    color : $secondary-color;
    margin-bottom : 0rem;
    cursor : default;
}

.accueil #accueil-content>div>div>a{
    color : $primary-color;
    font-size : 0.9rem;
}

.accueil #accueil-content>div>div>a:hover{
    text-decoration : underline;
}