		/*-----------------------------------------*/
		/* General Header (top-bar +  true-header) */
		/*-----------------------------------------*/		

.header.sticky-container{
    z-index : 50;
    height : 50px;
}

.header>div{
	padding-right : 0rem;
	padding-left : 0rem;
}

.header>.small-12.columns>.row#top-bar{
	max-width : none;
}	
		
		/*---------*/
		/* Top Bar */
		/*---------*/
		
/* Aligning the top-bar and the true header with the same padding value (0.5rem) */		
.header #top-bar_column{
	padding : 0rem;
	box-shadow : 0px 2px 1px $secondary-color;
}

.header a:hover{
	color : darkgray;
}

.header .top-bar{
    width : 100%;
}


.header .top-bar-right #top-bar-right-li-emploi{
	border-right : 1px solid black;
}

		/*-------------*/
		/* True Header */
		/*-------------*/
		
.header #true-header{
	padding : 0rem;
	border-bottom : 2px solid $secondary-color;
	padding-top : 1rem;
    padding-bottom : 1rem;
    margin-top : 0.2rem;
    margin-left : auto;
    z-index : 50;
}

	/*------*/
	/* Logo */
	/*----- */

/* Aligning the top-bar and the true header with the same padding value (0) */	

.header  #true-header #ath-logo-row{
    margin-left : 0rem;
    margin-right :0rem;
}

.header #true-header #ath-logo-container{
    padding : 0rem;
}

.header #true-header #ath-logo{
	padding : 0rem;
}

.header #true-header #ath-logo>img{
    margin : 0rem;
}

.header #true-header #ath-logo>*{
	padding : 0rem;
}

.header #true-header #ath-logo-p{
    padding : 0rem;
    cursor : default;
}

.header #true-header #ath-logo-p>p{
    font-variant: small-caps;
	font-size : 1.3rem;
	margin-bottom : 0rem;
	padding : 0rem;
    line-height : 1.5rem;
	padding-left : 8%;
}

.header #true-header #header-menu{
	padding : 0rem;
}	
	
/* Overriding the image inside .thumbnail in a_thumbnail.css*/

.header #true-header img{
	margin-left : 1%;
	margin-top : 1%;
	height : 3.5em;
}	

/* Overriding .thumbnail in a_thumbnail.css on hover*/

.header #true-header img:hover{
	cursor : pointer;
    box-shadow : 5px 5px 5px $primary-color;
}


        /*---------------*/
		/* Media Queries */
		/*---------------*/

@media screen and (max-width: 639px) {
	.header .top-bar{
		display: none;}
    .header div>#true-header{
        padding-top : 0.5rem;
        padding-bottom : 0.5rem;
        margin-top : 0rem;}
    .header #true-header #ath-logo-container #ath-logo-row{
        margin-left : 5%;}
    .header #true-header #ath-logo-container #ath-logo img{
		height : 2rem;}
    .header #true-header #ath-logo-container #ath-logo-p>p{
		font-size : 0.9rem;
        line-height : 0.9rem;}
    .header #true-header #header-menu>ul>li>a:hover{
        color : $primary-color;
        text-decoration : underline;
        box-shadow : none;
    }
}