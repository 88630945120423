/*---------------*/
/* Sticky header */
/*---------------*/

.shrink #top-bar{
    display : none;
}

.shrink #true-header{
    background-color : white;
    margin-top : 0rem;
    padding-bottom : 0.4rem;
    padding-top : 0.4rem;
    border-bottom : 2px double $primary-color;
    opacity : 0.95;
}

.shrink #true-header:hover{
    opacity : 1;
}

.shrink #ath-logo-p{
    display : none;
}

.shrink #ath-logo>img{
    max-height : 2.5rem;
}

.shrink #header-menu>ul#nav>li>a{
    font-size : 1.2rem;
    padding-bottom : 0.25rem;
}